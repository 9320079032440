@import "styles/_variables.scss";

.title {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 60px;
    color: $mainText;
    background: #FCFBF8;

    @media(max-width: $tabletPoint) {
        font-size: 36px;
        line-height: 48px;
    }

    &_none {
        background: #FCFBF8;
        display: inline;
        float: left;
        z-index: 10;
        position: relative;
    }

    &_green {
        width: 417px;
        display: inline;
        padding: 4px;
        background-color: #DEEEE6;
        position: relative;

        @media(max-width: $tabletPoint) {
            width: 661px;
        }

        @media(max-width: $mobilePoint) {
            width: 100%;
        }
    }

    &__block {
        max-width: 450px;

        @media(max-width: $tabletPoint) {
            max-width: 100%;
            margin: 0 auto;
        }
    }

    &__star {
        width: 56px;
        height: 56px;
        position: absolute;
        top: 0;
        right: 0;

        @media(max-width: $tabletPoint) {
            width: 48px;
            height: 48px;
            right: -45px;
        }

        @media(max-width: $mobilePoint) {
            display: none;
        }
    }
}

.subtitle {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: $mainText;
    margin: 24px 0 32px 0;

    @media(max-width: $tabletPoint) {
        text-align: center;
        margin: 20px 0 41px 0;
    }
}

.cards {
    display: flex;
    align-items: center;

    @media(max-width: $tabletPoint) {
        justify-content: space-between;
    }

    @media(max-width: $mobilePoint) {
        flex-direction: column;
    }

    &__item {
        display: flex;
        width: 282px;
        height: 351px;
        position: absolute;
        
        &:nth-child(1) {
            bottom: 0;
            left: 306px;
        }

        &:nth-child(2) {
            left: 612px;
            top: 87px;
        }

        &:nth-child(3) {
            top: 15px;
            right: 0;
        }

        @media(max-width: $tabletPoint) {
            position: relative;
            width: 226px;

            &:nth-child(1) {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            &:nth-child(2) {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            &:nth-child(3) {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }

        @media(max-width: $mobilePoint) {
            margin-bottom: 20px;
            width: 100%;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.flex {
    display: flex;
    position: relative;
    height: 623px;

    @media(max-width: $tabletPoint) {
        flex-direction: column;
        height: 100%;
    }
}

.line {
    position: absolute;
    bottom: 44px;
    width: 136px;
    height: 102px;
    top: 470px;
    left: 680px;

    @media(max-width: $tabletPoint) {
        display: none;
    }
}

.button {
    @media(max-width: $tabletPoint) {
        display: none;
    }
}